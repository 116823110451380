import { retry } from "~/backoff";
import { Table } from "~/common-types";
import { createClient } from "~/supabase";

export const getTablesByArea = retry(async () => {
  console.log("API: getTablesByArea");

  const supabase = createClient();

  const result = await supabase.rpc("get_orders_by_table");
  if (result.error) {
    console.error("error fetching tables", result.error.message);
    return {};
  }

  const res = result.data
    .sort(function sortByNameWithLastPartPossiblyBeANumber(a, b) {
      try {
        if (a.table_name == null) return -1;
        if (b.table_name == null) return 1;
        const [aName, aNumber] = a.table_name.split(" ");
        const [bName, bNumber] = b.table_name.split(" ");
        if (aName !== bName) {
          return aName.localeCompare(bName);
        }
        if (aNumber && bNumber) {
          return parseInt(aNumber) - parseInt(bNumber);
        }
        return a.table_name.localeCompare(b.table_name);
      } catch (e) {
        return a.table_name.localeCompare(b.table_name);
      }
    })
    .reduce<{ [area: string]: Table[] }>((tablesByArea, table) => {
      if (!tablesByArea[table.area_name]) {
        tablesByArea[table.area_name] = [];
      }
      tablesByArea[table.area_name].push({
        id: table.table_id,
        name: table.table_name,
        area: table.area_name,
        area_order: table.area_order,
        order_ids: Object.keys(
          table.order_ids.reduce<Record<string, true>>((acc, order_id) => {
            if (order_id) {
              acc[order_id] = true;
            }
            return acc;
          }, {}),
        ),
        total: table.total_amount,
      });
      return tablesByArea;
    }, {});
  return res;
}, 3);
